import React from "react";
import Card from "react-bootstrap/Card";

import Button from "react-bootstrap/Button";
import pdf from "../../Assets/Ahmed-CV.pdf";
import { AiOutlineDownload } from "react-icons/ai";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Ahmed Youssef </span>
            from <span className="purple"> Fayoum, Egypt.</span>
            <br />
            <br />
            A Full Stack Developer having an experience of building Web applications with JavaScript / Reactjs / ASP Core / Python and some other cool libraries and frameworks and Cross Platform Mobile Apps With Flutter.
            <br />
            <br />
          </p>        
          <p style={{ marginBlockEnd: 0, color: "rgb(155 126 172)" }}>
            {/* "Share what you can to benefit the others"{" "} */}
            <Button variant="primary" href={pdf} target="_blank">
            <AiOutlineDownload />
            &nbsp;Download CV
          </Button>
          </p>
          <footer className="blockquote-footer"> </footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
