import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";

import fouziaaldraihem from "../../Assets/Projects/fouziaaldraihem.jpg";
import insep from "../../Assets/Projects/insep.jpg";
import jubailevent from "../../Assets/Projects/jubailevent.jpg";
import scadaatech from "../../Assets/Projects/scadaatech.jpg";
import school from "../../Assets/Projects/school.jpg";
import st2oon from "../../Assets/Projects/st2oon.jpg";

import archive from "../../Assets/Projects/archive.jpg";
import clinicX from "../../Assets/Projects/clinicX.jpg";
import laundryBag from "../../Assets/Projects/laundryBag.jpg";
import RestaurantBag from "../../Assets/Projects/RestaurantBag.jpg";
import StoreBag from "../../Assets/Projects/StoreBag.jpg";

import sadiqatiapp from "../../Assets/Projects/sadiqatiapp.jpg";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Web <strong className="purple">applications </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few applications I've worked on.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={fouziaaldraihem}
              isWeb={true}
              title="Fouzia Aldraihem Website"
              description=""
              link="http://fouziaaldraihem.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={insep}
              isWeb={true}
              title="INSEP Website"
              description=""
              link="http://insep.net/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={jubailevent}
              isWeb={true}
              title="Jubail Events Website"
              description=""
              link="http://jubailevent.com"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={scadaatech}
              isWeb={true}
              title="SCADAA Tech Website"
              description=""
              link="http://scadaatech.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={st2oon}
              isWeb={true}
              title="St2oon Website"
              description=""
              link="http://st2oon.net/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={school}
              isWeb={true}
              title="School Website"
              description=""
              link="http://school.7srv.com/"
            />
          </Col>
         
        </Row>     
        <br /> 
        <br /> 
        <br /> 
        <h1 className="project-heading">
            Desktop <strong className="purple"> applications </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few applications I've worked on.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={StoreBag}
              isWeb={false}
              title="Store Application"
              description=""
              link=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={RestaurantBag}
              isWeb={false}
              title="Restaurant Application"
              description=""
              link=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={laundryBag}
              isWeb={false}
              title="laundry Application"
              description=""
              link=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={archive}
              isWeb={false}
              title="Archive Application"
              description=""
              link=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={clinicX}
              isWeb={false}
              title="Clinic Application"
              description=""
              link=""
            />
          </Col>
         
        </Row>  
        <br /> 
        <br /> 
        <br /> 
        <h1 className="project-heading">
            Mobile
             <strong className="purple"> applications </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few applications I've worked on.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={sadiqatiapp}
              isWeb={true}
              title="Sadiqati Application"
              description=""
              link="https://play.google.com/store/apps/details?id=com.myapp.sadiqatiapp"
            />
          </Col>

         
        </Row>     
      </Container>
    </Container>
  );
}

export default Projects;
